import React from 'react';
import { VStack, Box } from '@chakra-ui/react';

import VideoPlayerPatient from '../components/VideoPlayerPatient';

import './ProblemPage.css';
        
export default function ProblemPage() {
    return (

    <VStack className="Problem-header">
            <Box fontSize='vlg' fontFamily='FFDaxProRegular'>
                Getting a diagnosis
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                The journey to diagnosing a chronic or rare condition can be challenging, frustrating, and financially burdensome. Meaningful progress may take months, or even years, to achieve.
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                Patients may not be equipped to navigate the process efficiently, whether due to language barriers, lack of medical training, deteriorating health, or other challenges.
            </Box>
            <Box>
                <VideoPlayerPatient />
            </Box>
    </VStack>
    )
};