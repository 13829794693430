import React from 'react';

const VideoPlayerToxins = () => {
    return (
        <div>
            <video autoPlay loop playsInline width="640" height="360">
                <source src="/videos/Toxins.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default VideoPlayerToxins;