/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { InView } from 'react-intersection-observer';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

import './HomePage.css';

import ProblemPage from './ProblemPage';
import SolutionPage from './SolutionPage';
import MicroblogPage from './MicroblogPage';
import TechnologyPage from './TechnologyPage';
import AboutPage from './AboutPage';
import DevelopersPage from './DevelopersPage';
import IntellectualPropertyPage from './IntellectualPropertyPage';

import Stethoscope from './../Images/Stethoscope.png';
import HealthJournal from './../Images/HealthJournal.png';
import VitruvianMan from './../Images/VitruvianMan.jpg';
import TextMessage from './../Images/TextMessage.png';

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='txtMedic'
      inverted
      style={{
        fontFamily: 'AvianoSansLayersCenterlineReversed, Courier',
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content='Text messaging health journal for chronic and rare conditions'
      inverted
      style={{
        fontFamily: 'FFDaxProRegular, Courier',
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <Button primary size='huge' fontFamily ='FFDaxProRegular' onClick={() => alert('txtMedic will be launching early 2025. Stay tuned!')}>
      Get txtMedic
      <Icon name='right arrow' />
    </Button>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = { activeItem: 'home', fixed: false };

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView });

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { children } = this.props;
    const { fixed, activeItem } = this.state;

    return (
      <Media greaterThan='mobile'>
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            id="home"
            inverted
            textAlign='center'
            style={{
              minHeight: 700,
              padding: '1em 0em',
              backgroundImage: `url(${VitruvianMan})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item
                  name="home"
                  href="#home"
                  active={activeItem === 'home'}
                  onClick={this.handleItemClick}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  name="product"
                  href="#product"
                  active={activeItem === 'product'}
                  onClick={this.handleItemClick}
                >
                  Product
                </Menu.Item>
                <Menu.Item
                  name="ip"
                  href="#ip"
                  active={activeItem === 'ip'}
                  onClick={this.handleItemClick}
                >
                  I.P.
                </Menu.Item>
                <Menu.Item
                  name="about"
                  href="#about"
                  active={activeItem === 'about'}
                  onClick={this.handleItemClick}
                >
                  About
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </InView>

        {children}
      </Media>
    );
  }
}


DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = { activeItem: 'home', sidebarOpened: false };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  handleItemClick = (e, { name }) => this.setState({ activeItem: name, sidebarOpened: false });

  render() {
    const { children } = this.props;
    const { sidebarOpened, activeItem } = this.state;

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item
              name="home"
              href="#home"
              active={activeItem === 'home'}
              onClick={this.handleItemClick}
            >
              Home
            </Menu.Item>
            <Menu.Item
              name="product"
              href="#product"
              active={activeItem === 'product'}
              onClick={this.handleItemClick}
            >
              Product
            </Menu.Item>
            <Menu.Item
              name="ip"
              href="#ip"
              active={activeItem === 'ip'}
              onClick={this.handleItemClick}
            >
              I.P.
            </Menu.Item>
            <Menu.Item
              name="about"
              href="#about"
              active={activeItem === 'about'}
              onClick={this.handleItemClick}
            >
              About
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 350, backgroundImage: `url(${VitruvianMan})`, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}


MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer >{children}</DesktopContainer>
    <MobileContainer >{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={7}>
            <ProblemPage />
          </Grid.Column>
          <Grid.Column floated='right' width={6} >
            <Image size='large' src={Stethoscope} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment id="product" style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column width={7}>
            <SolutionPage />
        </Grid.Column>
         <Grid.Column floated='right' width={6}>
            <Image size='large' src={HealthJournal} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
        <Grid.Column width={7}>
            <MicroblogPage />
        </Grid.Column>
         <Grid.Column floated='right' width={6}>
            <Image size='large' src={TextMessage} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <TechnologyPage />
      </Container>
    </Segment>

    <Segment id="about" style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <AboutPage />
      </Container>
    </Segment>

    <Segment id="ip" style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <IntellectualPropertyPage />
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <a href='#'>Research @ txtMedic</a>
        </Divider>
        <DevelopersPage />
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <List link inverted>
                <List.Item name="about" href="#about"><p style={{ fontFamily: 'FFDaxProRegular, Courier' }}>About</p></List.Item>
                <List.Item as='a'><p style={{ fontFamily: 'FFDaxProRegular, Courier' }}>Contact Us</p></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <List link inverted>
                <List.Item as='a'><p style={{ fontFamily: 'FFDaxProRegular, Courier' }}>I.P. Licensing</p></List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
                <p style={{ fontFamily: 'FFDaxProRegular, Courier' }}>
                    <span>&#169;</span> 2024 txtMedic, All Rights Reserved.
                </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default HomepageLayout