import React from 'react';
import { VStack, Box } from '@chakra-ui/react';

import VideoPlayerDubai from '../components/VideoPlayerDubai';

import './MicroblogPage.css';
        
export default function MicroblogPage() {
    return (

    <VStack className="Microblog-header">
            <Box pb={4} fontSize='vlg' fontFamily='FFDaxProRegular'>
                100% text messaging
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                Patients will appreciate the ease and simplicity of text messaging to keep a <b>microblog-styled</b> journal of symptoms.
            </Box>
            <Box>
                <VideoPlayerDubai />
            </Box>
    </VStack>

    )
};