import React, { useEffect, useRef } from 'react';
import { Container, Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import Lottie from 'lottie-web';
import Patent from '../lotties/Patent.json';

import './../custom-fonts.css';
import './IntellectualPropertyPage.css';

const IntellectualPropertyAnimation = () => {
    const lottieContainer = useRef(null);
  
    useEffect(() => {
      const anim = Lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: Patent,
      });
  
      return () => {
        anim.destroy();
      };
    }, []);
  
    return <div ref={lottieContainer} />;
  };

export default function DevelopersPage() {
    return (
        <VStack className="IntellectualProperty-header">
            <Box fontSize='vlg' fontFamily='FFDaxProRegular'>
                I.P.
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                Technologies used in txtMedic are protected by issued U.S. patents 7551935, 7580719, 8472985, 8472986, 8489132, 8509826, 8509827, 8515468
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                We are open to synergistic licensing opportunities. 
            </Box>
            <IntellectualPropertyAnimation />
        </VStack>
    )
};