import React, { useEffect, useRef } from 'react';
import { Container, Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import Lottie from 'lottie-web';
import Rocket from '../lotties/Rocket.json';
import SuccessFlag from '../lotties/SuccessFlag.json';

import './../custom-fonts.css';
import './AboutPage.css';

const RocketAnimation = () => {
    const lottieContainer = useRef(null);
  
    useEffect(() => {
      const anim = Lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: Rocket,
      });
  
      return () => {
        anim.destroy();
      };
    }, []);
  
    return <div ref={lottieContainer} />;
  };

  const SuccessFlagAnimation = () => {
    const lottieContainer = useRef(null);
  
    useEffect(() => {
      const anim = Lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: SuccessFlag,
      });
  
      return () => {
        anim.destroy();
      };
    }, []);
  
    return <div ref={lottieContainer} />;
  };

export default function AboutPage() {
    return (
        <VStack className="IntellectualProperty-header">
            <Box fontSize='vlg' fontFamily='FFDaxProRegular'>
                About
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                txtMedic is a startup located in Palo Alto, CA. 
            </Box>
            <RocketAnimation />
            <Box fontSize='vlg' fontFamily='FFDaxProRegular'>
                Our vision
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                Help empower patients to maximize the effectiveness of their doctor's visit. 
            </Box>
            <SuccessFlagAnimation />
        </VStack>
    )
};