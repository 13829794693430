import React, { useEffect, useRef } from 'react';
import { Container, Box, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import Lottie from 'lottie-web';
import LightBulb from '../lotties/LightBulb.json';

import './../custom-fonts.css';
import './DevelopersPage.css';

const DevelopersAnimation = () => {
    const lottieContainer = useRef(null);
  
    useEffect(() => {
      const anim = Lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LightBulb,
      });
  
      return () => {
        anim.destroy();
      };
    }, []);
  
    return <div ref={lottieContainer} />;
  };

export default function DevelopersPage() {
    return (
        <VStack className="DevelopersPage-header">
            <Box fontSize='vlg' fontFamily='FFDaxProRegular'>
                Developers
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                Come build innovative lab tests and scans on our platform.
            </Box>
            <Box>
                <DevelopersAnimation />
            </Box>
            <Button fontSize='lg' fontFamily ='FFDaxProRegular' onClick={() => alert('hello')}>
                    Contact Us
            </Button>
        </VStack>
    )
};