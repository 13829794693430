import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { PrimeReactProvider } from 'primereact/api';

import HomePage from './Pages/HomePage';

const App = () => {
  return (
    <PrimeReactProvider>
      <ChakraProvider>
          <HomePage />
      </ChakraProvider>
    </PrimeReactProvider>
  );
}

export default App;
