import React from 'react';
import { VStack, Box } from '@chakra-ui/react';

import VideoPlayerSymptoms from '../components/VideoPlayerSymptoms';

import './SolutionPage.css';
        
export default function SolutionPage() {
    return (

    <VStack className="Solution-header">
            <Box pb={4} fontSize='vlg' fontFamily='FFDaxProRegular'>
                Health journal
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                txtMedic levels the playing field by enabling patients to articulate their symptoms with the precision of a medical professional through a <b>groundbreaking texting-based health journal</b>.
            </Box>
            <Box pb={4} fontSize='lg' fontFamily='FFDaxProRegular' textAlign='center'>
                Our AI converts a patient’s timeline of self-reported symptoms and mobile diagnostic tests into a clinical-grade symptoms roadmap, helping doctors arrive at the right diagnosis faster.
            </Box>
            <Box>
                <VideoPlayerSymptoms />
            </Box>
    </VStack>

    )
};